import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Members = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="メンバー紹介｜About us｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-aboutusPage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10">
            About us
          </h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <Link to="/aboutus-data" className=''>
                データで見るSUP
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-environment" className=''>
                働く環境
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-members" className=''>
                メンバー紹介
              </Link>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

            <section id="PageDescription" className="w-full mb-5 flex flex-wrap items-center justify-center lg:justify-evenly ">
              <h2 className="font-Copperplate text-center text-xl sm:text-3xl leading-loose sm:leading-loose py-5">
                <span className="text-2xl sm:text-4xl ">スタートアッププラス</span>の<br />
                <span className="text-4xl sm:text-5xl text-key-pink">メンバー紹介</span>
              </h2>
              <StaticImage src="../images/members-top.png" className=" w-[330px] h-auto ml-3"/>
            </section>

            <section id="members" className="mb-20">
              <div className="flex flex-wrap justify-center sm:justify-start">
                <div className="max-w-[280px] w-full sm:w-48 p-3">
                  <StaticImage src="../images/icon-kuwayama.png" className="w-full h-auto"/>
                  <p className="font-Copperplate text-center text-xs mt-3 mb-2">代表取締役社長｜Director</p>
                  <p className="text-center text-sm mb-5 font-medium">桒山 雄介</p>
                </div>
                <div className="max-w-[280px] w-full sm:w-48 p-3">
                  <StaticImage src="../images/icon-hirota.png" className="w-full h-auto"/>
                  <p className="font-Copperplate text-center text-xs mt-3 mb-2">取締役｜System Designer</p>
                  <p className="text-center text-sm mb-5 font-medium">廣田 徹也</p>
                </div>
                <div className="max-w-[280px] w-full sm:w-48 p-3">
                  <Link to="/members/nomura" className="">
                    <StaticImage src="../images/kantoku.png" className="w-full h-auto block hover:animate-bounce"/>
                    <p className="font-Copperplate text-center text-xs mt-3 mb-2">Chief Engineer</p>
                    <p className="text-center text-sm mb-5 font-medium">野村</p>
                  </Link>
                </div>
                <div className="max-w-[280px] w-full sm:w-48 p-3">
                  <Link to="/members/kyoujyu" className="">
                    <StaticImage src="../images/kyoujyu.png" className="w-full h-auto block hover:animate-bounce"/>
                    <p className="font-Copperplate text-center text-xs mt-3 mb-2">Programmer</p>
                    <p className="text-center text-sm mb-5 font-medium">きょうじゅ</p>
                  </Link>
                </div>
                <div className="max-w-[280px] w-full sm:w-48 p-3">
                  <Link to="/members/fujikawa" className=" ">
                    <StaticImage src="../images/fujimon.png" className="w-full h-auto block hover:animate-bounce"/>
                    <p className="font-Copperplate text-center text-xs mt-3 mb-2">Programmer</p>
                    <p className="text-center text-sm mb-5 font-medium">ふじもん</p>
                  </Link>
                </div>
                <div className="max-w-[280px] w-full sm:w-48 p-3">
                  <Link to="/members/csan" className="">
                    <StaticImage src="../images/csan.png" className="w-full h-auto block hover:animate-bounce"/>
                    <p className="font-Copperplate text-center text-xs mt-3 mb-2">Data Analyst</p>
                    <p className="text-center text-sm mb-5 font-medium">Ｃさん</p>
                  </Link>
                </div>
                <div className="max-w-[280px] w-full sm:w-48 p-3">
                  <Link to="/members/tadao" className="">
                    <StaticImage src="../images/tadao.png" className="w-full h-auto block hover:animate-bounce"/>
                    <p className="font-Copperplate text-center text-xs mt-3 mb-2">Data Analyst</p>
                    <p className="text-center text-sm mb-5 font-medium">忠男</p>
                  </Link>
                </div>
                <div className="max-w-[280px] w-full sm:w-48 p-3">
                  <Link to="/members/ryuryu" className="">
                    <StaticImage src="../images/ryuryu.png" className="w-full h-auto block hover:animate-bounce"/>
                    <p className="font-Copperplate text-center text-xs mt-3 mb-2">Data Analyst</p>
                    <p className="text-center text-sm mb-5 font-medium">リュウリュウ</p>
                  </Link>
                </div>
                <div className="max-w-[280px] w-full sm:w-48 p-3">
                  <Link to="/members/shotaro" className="">
                    <StaticImage src="../images/shotaro.png" className="w-full h-auto block hover:animate-bounce"/>
                    <p className="font-Copperplate text-center text-xs mt-3 mb-2">Data Analyst</p>
                    <p className="text-center text-sm mb-5 font-medium">しょたろ</p>
                  </Link>
                </div>
                <div className="max-w-[280px] w-full sm:w-48 p-3">
                  <Link to="/members/kishimoto" className="">
                    <StaticImage src="../images/kishimoto.png" className="w-full h-auto block hover:animate-bounce"/>
                    <p className="font-Copperplate text-center text-xs mt-3 mb-2">Data Analyst</p>
                    <p className="text-center text-sm mb-5 font-medium">Kishimoto</p>
                  </Link>
                </div>
                <div className="max-w-[280px] w-full sm:w-48 p-3">
                  <Link to="/members/yy" className=" ">
                    <StaticImage src="../images/yy.png" className="w-full h-auto block hover:animate-bounce"/>
                    <p className="font-Copperplate text-center text-xs mt-3 mb-2">Back Office</p>
                    <p className="text-center text-sm mb-5 font-medium">Y.Y</p>
                  </Link>
                </div>
                <div className="max-w-[280px] w-full sm:w-48 p-3">
                  <Link to="/members/a-s" className="">
                    <StaticImage src="../images/a-s.png" className="w-full h-auto block hover:animate-bounce"/>
                    <p className="font-Copperplate text-center text-xs mt-3 mb-2">Data Entry</p>
                    <p className="text-center text-sm mb-5 font-medium">A.S</p>
                  </Link>
                </div>
                <div className="max-w-[280px] w-full sm:w-48 p-3">
                  <Link to="/members/m-c" className="">
                    <StaticImage src="../images/m-c.png" className="w-full h-auto block hover:animate-bounce"/>
                    <p className="font-Copperplate text-center text-xs mt-3 mb-2">Data Entry</p>
                    <p className="text-center text-sm mb-5 font-medium">M.C</p>
                  </Link>
                </div>
              </div>
            </section>

          </div>
        </div>
      </div>

    </Layout>

    
  )
}



export default Members

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

